import { SupplyPeriodSummary } from 'components/connections/connectionsApi';
import {
  addHours,
  addMilliseconds,
  addMinutes,
  addMonths,
  addSeconds,
  differenceInHours,
  differenceInMinutes,
  eachMonthOfInterval,
  endOfMonth,
  endOfYear,
  format,
  isAfter,
  isBefore,
  isSameDay,
  isToday,
  isValid,
  isWithinInterval,
  lightFormat,
  parse,
  startOfMonth,
  startOfToday,
  startOfYear,
  subHours,
  subMonths,
  subYears,
} from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { addMonthsInZone, startOfMonthInZone } from '@developers/flux-date-util';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import dst from 'dst-transitions';
import { compose, isEmpty, isNil, propOr, sortBy, toLower } from 'ramda';
import { useStore } from 'store';
import getConfig from 'config/getConfig';
import moment from 'moment-timezone';

export const copyToClipboard = async (text: string) => {
  await navigator.clipboard.writeText(text);

  useStore.getState().addNotification({
    title: 'Copied to clipboard.',
    id: 'CopiedSuccess',
    color: 'success',
  });
};

/**
 * Method to parse a URN and return the namespace and separated identifiers.
 * @param urn the URN to parse
 * @returns Object containing the URN's namespace and separated identifiers.
 * @example
 * // returns { namespace: "example", identifiers: ["1234", "5678", "abcd", "efgh"] }
 * parseURN("urn:example:1234:5678:abcd:efgh");
 * @author ChatGPT
 */
export function parseURN(urn: string) {
  const urnRegex = /^urn:([a-zA-Z0-9-]+):(.+)$/;
  const matches = urn.match(urnRegex);

  if (matches) {
    const identifiers = matches[2].split(':');
    const parsed = {
      namespace: matches[1],
      identifiers: identifiers,
    };

    return parsed;
  } else {
    throw new Error('Invalid URN format');
  }
}

/**
 * Validates the given date string against using the given format (like 'yyyy-MM-dd')
 * @param dateToValidate The date string to test
 * @param dateFormat The format of the date string
 */
export const isValidateDate = (dateToValidate: string, dateFormat: string) => {
  const parsedDate = parse(dateToValidate, dateFormat, new Date());
  return isValid(parsedDate);
};

export const formatDateForDisplay = (date: string | undefined): string => {
  return date ? format(new Date(date), 'd MMM yyyy') : '';
};

export const formatDateForDatepicker = (date: string | undefined): string => {
  return date ? format(new Date(date), 'yyyy-MM-dd') : '';
};

export const capitaliseFirstText = (text: string | undefined): string => {
  if (isNil(text) || isEmpty(text)) {
    return '';
  }

  if (text.length === 1) {
    return text.toUpperCase();
  }

  return text.substr(0, 1).toUpperCase() + text.substring(1).toLowerCase();
};

export function alphabeticalSortByObjectProp(property: string): <T>(list: readonly T[]) => T[] {
  return sortBy(compose(toLower, propOr(property, '')));
}

export const formatTimestamp = (dateTime: string, maxMins = 60, maxHours = 8) => {
  const now = new Date();
  const date = new Date(dateTime);
  const isTodayDate = isToday(date);
  const minutesDiff = differenceInMinutes(now, date);
  const hoursDiff = differenceInHours(now, date);

  return minutesDiff < maxMins
    ? `${minutesDiff} minutes ago`
    : minutesDiff < 60 * maxHours
    ? `${hoursDiff} ${hoursDiff === 1 ? 'hour' : 'hours'} ago`
    : isTodayDate
    ? format(date, 'h:mma')
    : format(date, 'd MMM yyyy');
};

export interface FieldOption {
  label: string;
  value: string;
}

export const generateDateOptions = (
  useStart = true,
  count = 24,
  labelFormat = 'MMMM yyyy',
  valueFormat = 'yyyy-MM-dd',
  initialDate: Date = new Date(),
  exclusiveDate = false
): FieldOption[] => {
  const options = [];

  for (let i = 0; i <= count; i += 1) {
    const date = subMonths(initialDate, i);

    const option = useStart ? startOfMonth(date) : endOfMonth(date);
    const value = exclusiveDate ? addSeconds(option, 1) : option;

    options.push({
      label: format(option, labelFormat),
      value: format(value, valueFormat),
    });
  }

  return options;
};

export const generateYearlyDateOptions = (
  useStart = true,
  count = 5,
  labelFormat = 'yyyy',
  valueFormat = 'yyyy-MM-dd',
  initialDate: Date = new Date(),
  exclusiveDate = false
): FieldOption[] => {
  const options = [];

  for (let i = 0; i <= count; i += 1) {
    const date = subYears(initialDate, i);

    const option = useStart ? startOfYear(date) : endOfYear(date);
    const value = exclusiveDate ? addSeconds(option, 1) : option;

    options.push({
      label: format(option, labelFormat),
      value: format(value, valueFormat),
    });
  }

  return options;
};

export const generateDateOptionsBetweenTwoPoints = (
  startDate: Date,
  endDate: Date,
  startofmonth: boolean,
  labelFormat = 'MMMM yyyy',
  valueFormat = 'yyyy-MM-dd',
  exclusiveDate = false
): FieldOption[] => {
  const options = [];

  let date = startDate;
  while (date < endDate) {
    const option = startofmonth ? startOfMonth(date) : endOfMonth(date);

    options.push({
      label: format(option, labelFormat),
      value: format(exclusiveDate ? addMilliseconds(option, 1) : option, valueFormat),
    });
    date = addMonths(date, 1);
  }

  return options;
};

export const generateTimeIntervals = (
  interval = 30,
  timeFormat = 'HH:mm',
  displayRange = true,
  date?: Date
) => {
  const intervals = [];

  // find when DST changes over
  const transitions = date ? dst.get_transitions(date.getFullYear()) : null;
  const fall = transitions ? new Date(transitions.fall.to) : null;
  const fallRepeatTime = fall ? lightFormat(fall, timeFormat) : null;
  const spring = transitions ? new Date(transitions.spring.to) : null;
  const springSkipTime = spring ? lightFormat(spring, timeFormat) : null;

  // determine if we should repeat or skip time
  const repeatDay = date && fall ? isSameDay(fall, date) : false;
  const skipDay = date && spring ? isSameDay(spring, date) : false;

  let current = startOfToday();
  let hasSkipped = false;
  let hasRepeated = false;

  do {
    const time = lightFormat(current, timeFormat);
    // check what the next interval would be
    const next = addMinutes(current, interval);

    const period = displayRange ? `${time} - ${lightFormat(next, timeFormat)}` : time;

    intervals.push(period);

    // check what time it would be if we took an hour off the next time
    const repeatTime = subHours(next, 1);
    const shouldRepeat = lightFormat(repeatTime, timeFormat) === fallRepeatTime;

    // check what time it would be if we added an hour to the next time
    const skipTime = addHours(next, 1);
    const shouldSkip = lightFormat(skipTime, timeFormat) === springSkipTime;

    if (repeatDay && !hasRepeated) {
      current = shouldRepeat ? repeatTime : next;

      hasRepeated = shouldRepeat;
    } else if (skipDay && !hasSkipped) {
      current = shouldSkip ? skipTime : next;

      hasSkipped = shouldSkip;
    } else {
      current = next;
    }
  } while (isToday(current));

  return intervals;
};

export const sortByDate = (
  a: string | null | undefined,
  b: string | null | undefined,
  sortAsc: boolean
) => {
  if (a && !b) {
    return sortAsc ? -1 : 1;
  }

  if (!a && b) {
    return sortAsc ? 1 : -1;
  }

  if (!a && !b) {
    return 0;
  }

  if (sortAsc) {
    return isAfter(new Date(a ?? 0), new Date(b ?? 0)) ? 1 : -1;
  }

  return isAfter(new Date(a ?? 0), new Date(b ?? 0)) ? -1 : 1;
};

export const sortByStr = (a: string, b: string, sortAsc: boolean) => {
  const strA = a?.toUpperCase() ?? '';
  const strB = b?.toUpperCase() ?? '';
  if (sortAsc ? strA < strB : strA > strB) {
    return -1;
  }
  if (sortAsc ? strA > strB : strA < strB) {
    return 1;
  }

  return 0;
};

/**
 * Check if date range a overlaps date range b
 * @param a_start
 * @param a_end
 * @param b_start
 * @param b_end
 * @returns
 */
export const overlaps = (
  a_start: Date | number,
  a_end: Date | number,
  b_start: Date | number,
  b_end: Date | number
): boolean => {
  if (isBefore(a_start, b_start) && isBefore(b_start, a_end)) return true; // b starts in a
  if (isBefore(a_start, b_end) && isBefore(b_end, a_end)) return true; // b ends in a
  if (isBefore(b_start, a_start) && isBefore(a_end, b_end)) return true; // a in b
  return false;
};
/**
 * Returns true in case the 'dateToTest' date lies within the given period. This function assumes
 * that a period with undefined start date represents a period from the very past and vice versa.
 * Will always return false if start and end of the period is not defined or null.
 * @param dateToTest
 * @param datePeriodStart
 * @param datePeriodEnd
 */
export const isWithinPeriod = (
  dateToTest: Date,
  datePeriodStart: Date | null | undefined,
  datePeriodEnd: Date | null | undefined
) => {
  if (!datePeriodStart && datePeriodEnd) {
    return isBefore(dateToTest, datePeriodEnd);
  }
  if (datePeriodStart && !datePeriodEnd) {
    return isAfter(dateToTest, datePeriodStart);
  }
  if (datePeriodStart && datePeriodEnd) {
    return isWithinInterval(dateToTest, { start: datePeriodStart, end: datePeriodEnd });
  }
  return false;
};

/**
 * Groups the given array by the given function. Will return a Map.
 * @param array
 * @param grouper
 */
export function groupBy<K, V>(array: V[], grouper: (item: V) => K) {
  return array.reduce((store, item) => {
    const key = grouper(item);
    if (!store.has(key)) {
      store.set(key, [item]);
    } else {
      store.get(key)?.push(item);
    }
    return store;
  }, new Map<K, V[]>());
}

/**
 * Helper to create options for a select based on the start and end dates of supply periods.
 * Will create monthly options based on today's or the endDate date whichever is closer and
 * back until the start date is reached or stops earlier if 25 months back are reached. This
 * is to prevent the select being too large.
 * @param supplyPeriod
 */
export const generateDateOptionsBaseFromSupplyPeriod = (
  supplyPeriod: SupplyPeriodSummary
): FieldOption[] => {
  let monthlyIntervals: Date[] = [];
  const now = new Date();

  let endDate: Date = supplyPeriod.endDate ? new Date(supplyPeriod.endDate) : now;
  if (isAfter(endDate, now)) {
    endDate = now;
  }

  const startDate = new Date(supplyPeriod.startDate);
  // this can happen if the startDate of the SupplyPeriod is in the future, there are no metrics yet
  if (isAfter(startDate, endDate)) {
    endDate = startDate;
  }

  monthlyIntervals = monthlyIntervals.concat(
    eachMonthOfInterval({ start: startDate, end: endDate })
  );

  // remove duplicates by comparing the date.getTime() values for determining equality
  monthlyIntervals = monthlyIntervals.filter(
    (dateA, index, self) => self.findIndex((dateB) => dateA.getTime() === dateB.getTime()) == index
  );

  // sort dates desc
  monthlyIntervals.sort((dateA: Date, dateB: Date) => dateB.getTime() - dateA.getTime());

  // take only the first 25 entries to avoid large selects
  const monthlies = monthlyIntervals.slice(0, 25);

  const options: FieldOption[] = [];
  monthlies.map((value) => {
    options.push({
      label: format(value, 'MMMM yyyy'),
      value: format(value, 'yyyy-MM-dd'),
    });
  });

  return options;
};

export function newMarketDate(date?: any): Date {
  const config = getConfig();
  return new Date(
    (date ? new Date(date) : new Date()).toLocaleString('en-US', {
      timeZone: config.marketTimezone,
    })
  );
}

export function marketTimezoneAddMonths(date: Date, amount: number): Date {
  const config = getConfig();
  return addMonthsInZone(date, amount, config.marketTimezone);
}

export function marketTimezoneStartOfMonth(date: Date): Date {
  const config = getConfig();
  return startOfMonthInZone(date, config.marketTimezone);
}

export function marketStartOfMonth(date?: Date): Date {
  const config = getConfig();

  return zonedTimeToUtc(
    startOfMonth(utcToZonedTime(date ?? new Date(), config.marketTimezone)),
    config.marketTimezone
  );
}
export function marketEndOfMonth(date?: Date): Date {
  const config = getConfig();
  return zonedTimeToUtc(
    addMilliseconds(endOfMonth(utcToZonedTime(date ?? new Date(), config.marketTimezone)), 1),
    config.marketTimezone
  );
}

export function newUTCDate(date?: any): Date {
  const utcDate: Date = new Date(
    (date ? new Date(date) : new Date()).toLocaleString('en-US', {
      timeZone: 'UTC',
      hourCycle: 'h23',
    })
  );

  return utcDate;
}

export function utcDateTimeToMarketTime(date?: string): string {
  const config = getConfig();

  return date
    ? lightFormat(utcToZonedTime(date, config.marketTimezone), 'HH:mm:ss')
    : 'Not provided';
}

// if the input is a string its assumed to be formatted already (mostly for test purposes)
export function startOfMonthFormatted(dt: Date | string) {
  const formattedDate: string = dt instanceof Date ? format(startOfMonth(dt), 'yyyy-MM-dd') : dt;
  return formattedDate + 'T00:00:00.000' + getMarketOffset(formattedDate);
}

export function startOfDayFormatted(dt: Date | string) {
  const date = dt instanceof Date ? dt : newMarketDate(dt);
  const formattedDate: string = format(date, 'yyyy-MM-dd');

  return formattedDate + 'T00:00:00.000' + getMarketOffset(formattedDate);
}

/**
 * Returns the offset for a given date and hour on marketTimezone
 * We use the offset to simplify our queries as the hours and dates can be represented in a miniful
 * manner without any conversion (the offset represents the conversion)
 *
 * @param date
 */
export function getMarketOffset(date: string): string {
  const { dayLightSaving, marketTimezone, marketOffset } = getConfig();

  if (dayLightSaving) {
    const marketOffset: number = moment(date).tz(marketTimezone).utcOffset() / 60;
    const signal: string = marketOffset > 0 ? '+' : '-';
    let strOffset: string = Math.abs(marketOffset).toString();

    // make it two digits with prefix 0
    if (strOffset.toString().length < 2) {
      strOffset = '0' + strOffset;
    }

    return signal + strOffset + ':00';
  } else {
    return marketOffset;
  }
}

/**
 * Find variations (in hours) of the offset between two given dates
 * for daylight saving we may get +1 or -1 hour
 *
 * @param dt1
 * @param dt2
 */
export function getDiffOffset(dt1: Date | string, dt2: Date | string): number {
  const dt1Offset: number = moment(dt1).utcOffset() / 60;
  const dt2Offset: number = moment(dt2).utcOffset() / 60;

  return dt1Offset - dt2Offset;
}

/**
 * Returns groups of days with the same offset + the day, where the offset change happens
 * @param dt1
 * @param dt2
 */
export function findOffsetPeriods(dt1: string, dt2: string): Array<Period> {
  let date1 = moment(dt1, 'yyyy-MM-DD').toISOString();
  const date2 = moment(dt2, 'yyyy-MM-DD').toISOString();

  const periodsFound = Array<Period>();
  periodsFound.push(new Period(startOfDayFormatted(date1), ''));

  while (date1 < date2) {
    const date1PlusOneDay = moment(date1).add(1, 'day').toISOString();
    const offsetDifference = getDiffOffset(date1, date1PlusOneDay);

    if (offsetDifference) {
      periodsFound[periodsFound.length - 1].setEndDate(startOfDayFormatted(date1));

      periodsFound.push(
        new Period(startOfDayFormatted(date1), startOfDayFormatted(date1PlusOneDay))
      );

      periodsFound.push(new Period(startOfDayFormatted(date1PlusOneDay), ''));
    }
    date1 = date1PlusOneDay;
  }

  periodsFound[periodsFound.length - 1].setEndDate(startOfDayFormatted(date2));

  return periodsFound;
}

// Typescript POJO to encapsulate date ranges
export class Period {
  startDate: string;
  endDate: string;

  constructor(newStartDate: string, newEndDate: string) {
    this.startDate = newStartDate;
    this.endDate = newEndDate;
  }

  setEndDate(newEndDate: string) {
    this.endDate = newEndDate;
  }

  getEndDate(): string {
    return this.endDate;
  }

  setStartDate(newStartDate: string) {
    this.startDate = newStartDate;
  }

  getStartDate(): string {
    return this.startDate;
  }

  getOffsetDiff(): number {
    return getDiffOffset(this.startDate, this.endDate);
  }

  toFormattedRatingPeriod(): string {
    return this.formattedRatingPeriodStartDate() + ' - ' + this.formattedRatingPeriodEndDate();
  }

  private formattedRatingPeriodDate(date: Date, subtractADay: boolean): string {
    const dateToFormat = new Date(date);
    if (subtractADay) {
      dateToFormat.setDate(dateToFormat.getDate() - 1);
    }
    return format(dateToFormat, 'd MMM yyyy');
  }

  private formattedRatingPeriodStartDate(): string {
    return this.formattedRatingPeriodDate(newMarketDate(this.getStartDate()), false);
  }

  private formattedRatingPeriodEndDate(): string {
    return this.formattedRatingPeriodDate(newMarketDate(this.getEndDate()), true);
  }
}
